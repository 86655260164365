// routes
import { PATH_DASHBOARD, PATH_ADMIN_DASHBOARD, PATH_DEMO_DASHBOARD, PATH_EMPLOYEE_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  ocr: getIcon('ic_ocr'),
  task: getIcon('ic_task'),
};


// // Access the user state
// const userState = store.getState(); // assuming your user state is stored under 'user' key in the root reducer
// const role = window.localStorage.getItem('urole')
// const userrole = window.atob(role)

// Function to get the role from local storage
const getUserRole = () => {
  const role = window.localStorage.getItem('urole');
  return role ? window.atob(role) : null;
}

// Define navigation configuration based on user role
const getNavConfig = (userrole) => {
  if (userrole === 'adminUser') {
    return [
      // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'general',
      items: [{ title: 'app', path: PATH_ADMIN_DASHBOARD.general.app, icon: ICONS.dashboard }],
    },
  
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      items: [
        // OCR
        {
          title: 'ocr',
          path: PATH_ADMIN_DASHBOARD.ocr.root,
          icon: ICONS.ocr,
          children: [
            // { title: 'profile', path: PATH_DASHBOARD.user.profile },
            // { title: 'cards', path: PATH_DASHBOARD.user.cards },
            { title: 'collections', path: PATH_ADMIN_DASHBOARD.ocr.collections },
            { title: 'apicalls', path: PATH_ADMIN_DASHBOARD.ocr.apicalls },
            // { title: 'create', path: PATH_DASHBOARD.ocr.new },
            // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
            // { title: 'account', path: PATH_DASHBOARD.user.account },
          ],
        },
  
        // USER
        {
          title: 'user',
          path: PATH_ADMIN_DASHBOARD.ocr.user,
          icon: ICONS.user,
          children: [
            // { title: 'profile', path: PATH_DASHBOARD.user.profile },
            // { title: 'cards', path: PATH_DASHBOARD.user.cards },
            { title: 'list', path: PATH_ADMIN_DASHBOARD.user.list },
            { title: 'create', path: PATH_ADMIN_DASHBOARD.user.new },
            // { title: 'edit', path: PATH_ADMIN_DASHBOARD.user.demoEdit },
            { title: 'account', path: PATH_ADMIN_DASHBOARD.user.account },
          ],
        },

        // CRON
        {
          title: 'cron',
          path: PATH_ADMIN_DASHBOARD.ocr.cron,
          icon: ICONS.task,
          children: [
            // { title: 'profile', path: PATH_DASHBOARD.user.profile },
            // { title: 'cards', path: PATH_DASHBOARD.user.cards },
            { title: 'list', path: PATH_ADMIN_DASHBOARD.cron.list },
            { title: 'create', path: PATH_ADMIN_DASHBOARD.cron.new },
            // { title: 'edit', path: PATH_ADMIN_DASHBOARD.user.demoEdit },
            // { title: 'account', path: PATH_ADMIN_DASHBOARD.cron.account },
          ],
        },
  
        // INVOICE
        {
          title: 'invoice',
          path: PATH_ADMIN_DASHBOARD.invoice.root,
          icon: ICONS.invoice,
          children: [
            { title: 'list', path: PATH_ADMIN_DASHBOARD.invoice.list },
            { title: 'details', path: PATH_ADMIN_DASHBOARD.invoice.demoView },
            { title: 'create', path: PATH_ADMIN_DASHBOARD.invoice.new },
            { title: 'edit', path: PATH_ADMIN_DASHBOARD.invoice.demoEdit },
          ],
        },
      ],
    },
    ];
  } 
  
  if (userrole === 'demoUser') {
    return [
      // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'general',
      items: [{ title: 'app', path: PATH_DEMO_DASHBOARD.general.app, icon: ICONS.dashboard }],
    },
  
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      items: [
        // OCR
        {
          title: 'ocr',
          path: PATH_DEMO_DASHBOARD.ocr.root,
          icon: ICONS.ocr,
          children: [
            // { title: 'profile', path: PATH_DASHBOARD.user.profile },
            // { title: 'cards', path: PATH_DASHBOARD.user.cards },
            { title: 'fields', path: PATH_DEMO_DASHBOARD.ocr.fields },
            { title: 'collections', path: PATH_DEMO_DASHBOARD.ocr.collections },
            { title: 'apicalls', path: PATH_DEMO_DASHBOARD.ocr.apicalls },
            // { title: 'create', path: PATH_DASHBOARD.ocr.new },
            // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
            // { title: 'account', path: PATH_DASHBOARD.user.account },
          ],
        },
        
        // USER
        {
          title: 'user',
          path: PATH_DEMO_DASHBOARD.user.root,
          icon: ICONS.user,
          children: [
            // { title: 'profile', path: PATH_DASHBOARD.user.profile },
            // { title: 'cards', path: PATH_DASHBOARD.user.cards },
            // { title: 'list', path: PATH_EMPLOYEE_DASHBOARD.user.list },
            // { title: 'create', path: PATH_DASHBOARD.user.new },
            // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
            { title: 'account', path: PATH_DEMO_DASHBOARD.user.account },
          ],
        },
      ]
    }
    ];
  }
  
  if (userrole === 'employeeUser') {
    return [
      // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'general',
      items: [{ title: 'app', path: PATH_EMPLOYEE_DASHBOARD.general.app, icon: ICONS.dashboard }],
    },
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      items: [
        // OCR
        {
          title: 'ocr',
          path: PATH_EMPLOYEE_DASHBOARD.ocr.root,
          icon: ICONS.ocr,
          children: [
            // { title: 'profile', path: PATH_DASHBOARD.user.profile },
            // { title: 'cards', path: PATH_DASHBOARD.user.cards },
            { title: 'collections', path: PATH_EMPLOYEE_DASHBOARD.ocr.collections },
            { title: 'apicalls', path: PATH_EMPLOYEE_DASHBOARD.ocr.apicalls },
            // { title: 'create', path: PATH_DASHBOARD.ocr.new },
            // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
            // { title: 'account', path: PATH_DASHBOARD.user.account },
          ],
        },
  
        // USER
        {
          title: 'user',
          path: PATH_EMPLOYEE_DASHBOARD.user.root,
          icon: ICONS.user,
          children: [
            // { title: 'profile', path: PATH_DASHBOARD.user.profile },
            // { title: 'cards', path: PATH_DASHBOARD.user.cards },
            // { title: 'list', path: PATH_EMPLOYEE_DASHBOARD.user.list },
            // { title: 'create', path: PATH_DASHBOARD.user.new },
            // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
            { title: 'account', path: PATH_EMPLOYEE_DASHBOARD.user.account },
          ],
        },
      ]
    },
    ];
  } 
    
  return [
      // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'general',
      items: [{ title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
    },
  
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      items: [
        // OCR
        {
          title: 'ocr',
          path: PATH_DASHBOARD.ocr.root,
          icon: ICONS.ocr,
          children: [
            // { title: 'profile', path: PATH_DASHBOARD.user.profile },
            // { title: 'cards', path: PATH_DASHBOARD.user.cards },
            { title: 'fields', path: PATH_DASHBOARD.ocr.fields },
            { title: 'collections', path: PATH_DASHBOARD.ocr.collections },
            { title: 'apicalls', path: PATH_DASHBOARD.ocr.apicalls },
            // { title: 'create', path: PATH_DASHBOARD.ocr.new },
            // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
            // { title: 'account', path: PATH_DASHBOARD.user.account },
          ],
        },
  
        // USER
        {
          title: 'user',
          path: PATH_DASHBOARD.user.root,
          icon: ICONS.user,
          children: [
            // { title: 'profile', path: PATH_DASHBOARD.user.profile },
            // { title: 'cards', path: PATH_DASHBOARD.user.cards },
            // { title: 'list', path: PATH_DASHBOARD.user.list },
            // { title: 'create', path: PATH_DASHBOARD.user.new },
            // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
            { title: 'account', path: PATH_DASHBOARD.user.account },
          ],
        },
  
        // INVOICE
        {
          title: 'invoice',
          path: PATH_DASHBOARD.invoice.root,
          icon: ICONS.invoice,
          children: [
            { title: 'list', path: PATH_DASHBOARD.invoice.list },
            // { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
            // { title: 'create', path: PATH_DASHBOARD.invoice.new },
            // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
          ],
        },
      ],
    },
  ];  
}

// Get the user role and navigation configuration
const userrole = getUserRole();
const navConfig = getNavConfig(userrole)

export default navConfig;
