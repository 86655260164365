import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Business Plan', 'Premium Business Plan'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['API integration', 'Unlimited pdf extraction', '99% accuracy'],
  options: [
    // 'JavaScript version',
    // 'TypeScript version',
    // 'Design Resources',
    // 'Commercial applications',
  ],
  icons: [
    // 'https://minimal-assets-api.vercel.app/assets/images/home/ic_sketch.svg',
    // 'https://minimal-assets-api.vercel.app/assets/images/home/ic_figma.svg',
    // 'https://minimal-assets-api.vercel.app/assets/images/home/ic_js.svg',
    // 'https://minimal-assets-api.vercel.app/assets/images/home/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  {
    // subscription: 'standard plan',
    subscription: 'pay as you go plan',
    icon: <PlanFreeIcon />,
    price: '80',
    caption: '* Plus applicable taxes',
    lists: [
      { text: 'API integration', isAvailable: true },
      // { text: 'Up to 1 team members', isAvailable: true },
      { text: '99% accuracy', isAvailable: true },
      { text: 'Unlimited Collection training', isAvailable: true },
      { text: 'Unlimited pdf pages extraction', isAvailable: true },
      // { text: 'Single domain access', isAvailable: true },
      { text: 'Google Document AI/ Microsoft Azure Form Recognizer/ Amazon Textract', isAvailable: true },
      { text: 'Extensions (upcoming)', isAvailable: false },
      // { text: 'NLP on extracted data', isAvailable: false },
    ],
    labelAction: 'choose standard',
  },
  {
    subscription: 'business plan',
    icon: <PlanStarterIcon />,
    price: '3.5 lakh',
    caption: '* Plus applicable taxes',
    lists: [
      { text: 'API integration', isAvailable: true },
      // { text: 'Multiple team members', isAvailable: true },
      { text: '99% accuracy', isAvailable: true },
      { text: '10 Collection training', isAvailable: true },
      { text: 'Unlimited pdf pages extraction', isAvailable: true },
      // { text: 'Multiple domain access', isAvailable: true },
      { text: 'Google Document AI/ Microsoft Azure Form Recognizer/ Amazon Textract', isAvailable: true },
      { text: 'Extensions (upcoming)', isAvailable: false },
      // { text: 'NLP on extracted data', isAvailable: false },
    ],
    labelAction: 'choose business',
  },
  {
    subscription: 'premium business plan',
    icon: <PlanPremiumIcon />,
    price: '5.5 lakh',
    caption: '* Plus applicable taxes',
    lists: [
      { text: 'API integration', isAvailable: true },
      // { text: 'Multiple team members', isAvailable: true },
      { text: '99% accuracy', isAvailable: true },
      { text: 'Unlimited Collection training', isAvailable: true },
      { text: 'Unlimited pdf pages extraction', isAvailable: true },
      // { text: 'Multiple domain access', isAvailable: true },
      { text: 'Google Document AI/ Microsoft Azure Form Recognizer/ Amazon Textract', isAvailable: true },
      { text: 'Extensions (upcoming)', isAvailable: false },
      // { text: 'NLP on extracted data', isAvailable: true },
    ],
    labelAction: 'choose premium business',
  },
];
