// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------
export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const username = user?.firstName.concat(user?.lastName)
  return (
    <Avatar
      src={user?.avatar}
      alt={username}
      color={user?.photoURL ? 'default' : createAvatar(user?.firstName).color}
      {...other}
    >
      {createAvatar(user?.firstName).name}
    </Avatar>
  );
}
