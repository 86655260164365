// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ADMIN_DASHBOARD = '/admindashboard';
const ROOTS_DEMO_DASHBOARD = '/demodashboard';
const ROOTS_EMPLOYEE_DASHBOARD = '/employeedashboard';

// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  // register: path(ROOTS_AUTH, '/register'),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: (email) => path(ROOTS_AUTH, `/verify/${email}`),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: (email, code) => path(ROOTS_AUTH, `/new-password/${email}/${code}`)
};


export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  // payment: '/payment',
  // about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  privacy: '/privacy',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  ocr: {
    root: path(ROOTS_DASHBOARD, '/ocr'),
    new: path(ROOTS_DASHBOARD, '/ocr/new'),
    testcollection: path(ROOTS_DASHBOARD, '/ocr/testcollection'),
    newfield: path(ROOTS_DASHBOARD, '/ocr/newfield'),
    collections: path(ROOTS_DASHBOARD, '/ocr/collections'),
    fields: path(ROOTS_DASHBOARD, '/ocr/fields'),
    apicalls: path(ROOTS_DASHBOARD, '/ocr/apicalls'),
    view: (id) => path(ROOTS_DASHBOARD, `/ocr/${id}`),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    // new: path(ROOTS_DASHBOARD, '/user/new'),
    // list: path(ROOTS_DASHBOARD, '/user/list'),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    // new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    // edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
};

export const PATH_ADMIN_DASHBOARD = {
  root: ROOTS_ADMIN_DASHBOARD,
  general: {
    app: path(ROOTS_ADMIN_DASHBOARD, '/app'),
  },
  ocr: {
    root: path(ROOTS_ADMIN_DASHBOARD, '/ocr'),
    // new: path(ROOTS_ADMIN_DASHBOARD, '/ocr/new'),
    collections: path(ROOTS_ADMIN_DASHBOARD, '/ocr/collections'),
    apicalls: path(ROOTS_ADMIN_DASHBOARD, '/ocr/apicalls'),
    view: (id) => path(ROOTS_ADMIN_DASHBOARD, `/ocr/${id}`),
    manual: (id) => path(ROOTS_ADMIN_DASHBOARD, `/ocr/${id}/manual`),
    mapping: (id) => path(ROOTS_ADMIN_DASHBOARD, `/ocr/${id}/mapping`),
    fieldmapping: (id) => path(ROOTS_ADMIN_DASHBOARD, `/ocr/${id}/fieldmapping`),
    footermapping: (id) => path(ROOTS_ADMIN_DASHBOARD, `/ocr/${id}/footermapping`),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  user: {
    root: path(ROOTS_ADMIN_DASHBOARD, '/user'),
    new: path(ROOTS_ADMIN_DASHBOARD, '/user/new'),
    list: path(ROOTS_ADMIN_DASHBOARD, '/user/list'),
    account: path(ROOTS_ADMIN_DASHBOARD, '/user/account'),
    edit: (id) => path(ROOTS_ADMIN_DASHBOARD, `/user/${id}/edit`),
    demoEdit: path(ROOTS_ADMIN_DASHBOARD, `/user/reece-chung/edit`),
  },
  cron: {
    root: path(ROOTS_ADMIN_DASHBOARD, '/cron'),
    new: path(ROOTS_ADMIN_DASHBOARD, '/cron/new'),
    list: path(ROOTS_ADMIN_DASHBOARD, '/cron/list'),
    // account: path(ROOTS_ADMIN_DASHBOARD, '/cron/account'),
    edit: (id) => path(ROOTS_ADMIN_DASHBOARD, `/cron/${id}/edit`),
    demoEdit: path(ROOTS_ADMIN_DASHBOARD, `/cron/reece-chung/edit`),
  },
  invoice: {
    root: path(ROOTS_ADMIN_DASHBOARD, '/invoice'),
    list: path(ROOTS_ADMIN_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_ADMIN_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_ADMIN_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_ADMIN_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_ADMIN_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_ADMIN_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
};

export const PATH_DEMO_DASHBOARD = {
  root: ROOTS_DEMO_DASHBOARD,
  general: {
    app: path(ROOTS_DEMO_DASHBOARD, '/app'),
  },
  ocr: {
    root: path(ROOTS_DEMO_DASHBOARD, '/ocr'),
    new: path(ROOTS_DEMO_DASHBOARD, '/ocr/new'),
    testcollection: path(ROOTS_DEMO_DASHBOARD, '/ocr/testcollection'),
    newfield: path(ROOTS_DEMO_DASHBOARD, '/ocr/newfield'),
    collections: path(ROOTS_DEMO_DASHBOARD, '/ocr/collections'),
    fields: path(ROOTS_DEMO_DASHBOARD, '/ocr/fields'),
    apicalls: path(ROOTS_DEMO_DASHBOARD, '/ocr/apicalls'),
    view: (id) => path(ROOTS_DEMO_DASHBOARD, `/ocr/${id}`),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  user: {
    root: path(ROOTS_DEMO_DASHBOARD, '/user'),
    account: path(ROOTS_DEMO_DASHBOARD, '/user/account'),
  },
};

export const PATH_EMPLOYEE_DASHBOARD = {
  root: ROOTS_EMPLOYEE_DASHBOARD,
  general: {
    app: path(ROOTS_EMPLOYEE_DASHBOARD, '/app'),
  },
  ocr: {
    root: path(ROOTS_EMPLOYEE_DASHBOARD, '/ocr'),
    // new: path(ROOTS_ADMIN_DASHBOARD, '/ocr/new'),
    collections: path(ROOTS_EMPLOYEE_DASHBOARD, '/ocr/collections'),
    apicalls: path(ROOTS_EMPLOYEE_DASHBOARD, '/ocr/apicalls'),
    view: (id) => path(ROOTS_EMPLOYEE_DASHBOARD, `/ocr/${id}`),
    manual: (id) => path(ROOTS_EMPLOYEE_DASHBOARD, `/ocr/${id}/manual`),
    mapping: (id) => path(ROOTS_EMPLOYEE_DASHBOARD, `/ocr/${id}/mapping`),
    fieldmapping: (id) => path(ROOTS_EMPLOYEE_DASHBOARD, `/ocr/${id}/fieldmapping`),
    footermapping: (id) => path(ROOTS_EMPLOYEE_DASHBOARD, `/ocr/${id}/footermapping`),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  user: {
    root: path(ROOTS_EMPLOYEE_DASHBOARD, '/user'),
    account: path(ROOTS_EMPLOYEE_DASHBOARD, '/user/account'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
