import * as Yup from 'yup';
import { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------
import ReCAPTCHA from "react-google-recaptcha";


// routes
import { PATH_DASHBOARD, PATH_ADMIN_DASHBOARD, PATH_DEMO_DASHBOARD, PATH_EMPLOYEE_DASHBOARD } from '../../../routes/paths';
import axios from 'axios';

export default function LoginForm() {
  const recaptcha = useRef()
  const [captchaToken, setCaptchaToken] = useState("")

  const { enqueueSnackbar } = useSnackbar();

  const NODE_ENV = process.env.REACT_APP_NODE_ENV
  const CAPTCHA_KEY_LOCAL = process.env.REACT_APP_CAPTCHA_KEY_LOCAL
  const CAPTCHA_KEY_PROD = process.env.REACT_APP_CAPTCHA_KEY_PROD

  const { login } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    // remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;


  const onSubmit = async (data) => {
    try {
      const userres = await axios.post('/user/check-user-banned', {
        useremail: data.email
      })

      if(userres.data.msg === false) {
        enqueueSnackbar('Account is banned. Please contact us.')
      } else {

        const resp = await login(data.email, data.password, captchaToken);

        // console.log(resp)
        enqueueSnackbar(resp.msg)
        reset();
        // console.log(resp)
        if(resp?.id) {
          const urole = resp.id.role;
  
          if(urole === 'adminUser') {
            window.location.href = PATH_ADMIN_DASHBOARD.root
            // navigate(PATH_ADMIN_DASHBOARD.root, { replace: true });
          } else if(urole === 'demoUser') {
            window.location.href = PATH_DEMO_DASHBOARD.root
            // navigate(PATH_DEMO_DASHBOARD.root, { replace: true });
          } else if(urole === 'employeeUser') {
            window.location.href = PATH_EMPLOYEE_DASHBOARD.root
            // navigate(PATH_EMPLOYEE_DASHBOARD.root, { replace: true });
          } else {
            window.location.href = PATH_DASHBOARD.root
            // navigate(PATH_DASHBOARD.root, { replace: true });
          }
          // console.log('user role ', resp.id.role)
        } else {
          // console.log('else')
        }
      }
      
    } catch (error) {
      // console.error(error);
      enqueueSnackbar(error.response.data.msg)
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.response.data.msg });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <div style={{marginTop: 25+'px'}}>
      <ReCAPTCHA
          ref={recaptcha}
          sitekey={NODE_ENV === 'production' ? CAPTCHA_KEY_PROD : CAPTCHA_KEY_LOCAL}
          onChange={token => setCaptchaToken(token)}
          onExpired={e => setCaptchaToken("")}
        />
      </div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
