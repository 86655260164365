import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    // console.log(user)

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const resp = action.payload.user
    // console.log(resp)
    // console.log(state)
    // console.log(action)
    
    // const { user } = action.payload;
    // return {
    //   ...state,
    //   isAuthenticated: false,
    //   user: null,
    // };

    return {
      ...state,
      isAuthenticated: false,
      user: resp,
    };
  },
  UPDATEUSER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const userId = window.localStorage.getItem('uid');

        if (accessToken && userId && isValidToken(accessToken)) {
          setSession(accessToken);

          const userId = window.localStorage.getItem('uid')
          // console.log(userId)

          const response = await axios.get(`/user/account/${userId}`, {
            headers: {id: userId}
          });
          const user = response.data.msg;
          // console.log(user)
          
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password, captchaToken) => {
    try {
      const response = await axios.post('/user/login', {
        email,
        password,
        captchaToken
      });
      const resData = response.data;
      // console.log('147')
      // console.log(resData)

      setSession(resData.token);
      window.localStorage.setItem('accessToken', resData.token);
      window.localStorage.setItem('uid', resData.id._id)

      const userrole = resData.id.role
      window.localStorage.setItem('urole', window.btoa(userrole))

      dispatch({
        type: 'LOGIN',
        payload: {
          // isAuthenticated: true,
          user: resData.id,
        },
      });
  
      return resData;
    } catch (error) {
      // console.log(error)

      dispatch({
        type: 'LOGIN',
        payload: {
          // isAuthenticated: false,
          user: null,
        },
      });
  
      return error;
    }
    // const { accessToken, user } = response.data;

    // setSession(accessToken);
    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     user,
    //   },
    // });
  };

  const register = async (firstName, lastName, mobile, email, company, password, captchaToken) => {
    // console.log('inside register')
    const response = await axios.post('/user/register', {
      email,
      password,
      firstName,
      lastName,
      company, 
      mobile,
      captchaToken
    });

    // const { accessToken, user } = response.data;

    // window.localStorage.setItem('accessToken', accessToken);
    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user,
    //   },
    // });

    const resData = response.data
    // console.log(resData)
    
    dispatch({
      type: 'REGISTER',
      payload: {
        user: null,
      },
    });

    return resData;
  };

  const logout = async () => {
    setSession(null);
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('uid');
    window.localStorage.removeItem('urole')
    dispatch({ type: 'LOGOUT' });
  };

  const updateUser = async (id) => {
    // console.log('id')
    // console.log(id)
    const response = await axios.get(`/user/account/${id}`);
    const resp = response.data.msg;

    dispatch({
      type: 'UPDATEUSER',
      payload: {
        user: resp,
      },
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
