import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AdminRoleBasedGuard from 'src/guards/AdminRoleBasedGuard';
import DemoRoleBasedGuard from 'src/guards/DemoRoleBasedGuard';
import EmployeeRoleBasedGuard from 'src/guards/EmployeeRoleBasedGuard';
import UserRoleBasedGuard from 'src/guards/UserRoleBasedGuard';

// config
import { PATH_AFTER_USER_LOGIN, PATH_AFTER_DEMO_USER_LOGIN, PATH_AFTER_ADMIN_USER_LOGIN, PATH_AFTER_EMPLOYEE_USER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};


export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        { path: 'login', element: <Login /> },
        // { path: 'register', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify/:email', element: <VerifyCode /> },
        { path: 'account-activate/:email', element: <AccountActivateCode /> },
        { path: 'new-password/:email/:code', element: <NewPassword /> },
      ],
    },

    // Client User Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <UserRoleBasedGuard accessibleRoles={['clientUser']}>
            <DashboardLayout />
          </UserRoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_USER_LOGIN} replace />, index: true },
        { path: 'app', element: <UserDashboard /> },
        {
          path: 'ocr',
          children: [
            { element: <Navigate to="/dashboard/ocr/collections" replace />, index: true },
            { path: 'collections', element: <CollectionsList /> },
            { path: 'apicalls', element: <APICallsList /> },
            { path: 'fields', element: <FieldsList /> },
            { path: 'newfield', element: <FieldCreate /> },
            { path: ':id', element: <CollectionStart /> },
            // { path: ':id', element: <InvoiceDetails /> },
            // { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <CollectionCreate /> },
            { path: 'testcollection', element: <CollectionTest /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            // { path: 'profile', element: <UserProfile /> },
            // { path: 'cards', element: <UserCards /> },
            // { path: 'list', element: <UserList /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            // { path: ':id/edit', element: <InvoiceEdit /> },
            // { path: 'new', element: <InvoiceCreate /> },
          ],
        },
      ],
    },

    // Admin User Dashboard Routes
    {
      path: 'admindashboard',
      element: (
        <AuthGuard>
          <AdminRoleBasedGuard accessibleRoles={['adminUser']}>
            <DashboardLayout />
          </AdminRoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_ADMIN_USER_LOGIN} replace />, index: true },
        { path: 'app', element: <AdminUserDashboard /> },
        {
          path: 'ocr',
          children: [
            { element: <Navigate to="/admindashboard/ocr/collections" replace />, index: true },
            { path: 'collections', element: <AdminCollectionsList /> },
            { path: 'apicalls', element: <AdminAPICallsList /> },
            { path: ':id', element: <AdminCollectionStart /> },
            { path: ':id/manual', element: <AdminCollectionManual /> },
            { path: ':id/mapping', element: <AdminCollectionMapping /> },
            { path: ':id/fieldmapping', element: <AdminCollectionTableMapping /> },
            { path: ':id/footermapping', element: <AdminCollectionTableFooterMapping /> },
            // { path: ':id', element: <InvoiceDetails /> },
            // { path: ':id/edit', element: <InvoiceEdit /> },
            // { path: 'new', element: <CollectionCreate /> },
          ],
        },
        {
          path: 'cron',
          children: [
            { element: <Navigate to="/admindashboard/cron/list" replace />, index: true },
            // { path: 'account', element: <UserAccount /> },
            { path: 'list', element: <AdminCronList /> },
            { path: 'new', element: <CronCreate /> },
            { path: ':id/edit', element: <CronEdit /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/admindashboard/user/account" replace />, index: true },
            { path: 'account', element: <UserAccount /> },
            { path: 'list', element: <AdminUserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserEdit /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/admindashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <AdminInvoiceList /> },
            { path: ':id', element: <AdminInvoiceDetails /> },
            { path: ':id/edit', element: <AdminInvoiceEdit /> },
            { path: 'new', element: <AdminInvoiceCreate /> },
          ],
        },
      ],
    },

    // Demo User Dashboard Routes
    {
      path: 'demodashboard',
      element: (
        <AuthGuard>
          <DemoRoleBasedGuard accessibleRoles={['demoUser']}>
            <DashboardLayout />
          </DemoRoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_DEMO_USER_LOGIN} replace />, index: true },
        { path: 'app', element: <DemoUserDashboard /> },
        {
          path: 'ocr',
          children: [
            { element: <Navigate to="/demodashboard/ocr/collections" replace />, index: true },
            { path: 'collections', element: <DemoUserCollectionsList /> },
            { path: 'apicalls', element: <DemoUserAPICallsList /> },
            { path: 'fields', element: <DemoUserFieldsList /> },
            { path: 'newfield', element: <DemoUserFieldCreate /> },
            { path: ':id', element: <DemoUserCollectionStart /> },
            // { path: ':id', element: <InvoiceDetails /> },
            // { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <DemoUserCollectionCreate /> },
            { path: 'testcollection', element: <DemoUserCollectionTest /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/demodashboard/user/list" replace />, index: true },
            // { path: 'profile', element: <UserProfile /> },
            // { path: 'cards', element: <UserCards /> },
            // { path: 'list', element: <UserList /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
      ],
    },

    // Employee User Dashboard Routes
    {
      path: 'employeedashboard',
      element: (
        <AuthGuard>
          <EmployeeRoleBasedGuard accessibleRoles={['employeeUser']}>
            <DashboardLayout />
          </EmployeeRoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_EMPLOYEE_USER_LOGIN} replace />, index: true },
        { path: 'app', element: <EmployeeUserDashboard /> },
        {
          path: 'ocr',
          children: [
            { element: <Navigate to="/employeedashboard/ocr/collections" replace />, index: true },
            { path: 'collections', element: <EmployeeCollectionsList /> },
            { path: 'apicalls', element: <EmployeeAPICallsList /> },
            { path: ':id', element: <EmployeeCollectionStart /> },
            { path: ':id/manual', element: <EmployeeCollectionManual /> },
            { path: ':id/mapping', element: <EmployeeCollectionMapping /> },
            { path: ':id/fieldmapping', element: <EmployeeCollectionTableMapping /> },
            { path: ':id/footermapping', element: <EmployeeCollectionTableFooterMapping /> },
            // { path: ':id', element: <InvoiceDetails /> },
            // { path: ':id/edit', element: <InvoiceEdit /> },
            // { path: 'new', element: <CollectionCreate /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/employeedashboard/user/account" replace />, index: true },
            { path: 'account', element: <UserAccount /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        // { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'privacy', element: <Privacy /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const AccountActivateCode = Loadable(lazy(() => import('../pages/auth/AccountActivate')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));

// DASHBOARD

// USER DASHBOARD
const UserDashboard = Loadable(lazy(() => import('../pages/dashboard/UserDashboard')));

// DEMO USER DASHBOARD
const DemoUserDashboard = Loadable(lazy(() => import('../pages/dashboard/DemoUserDashboard')));

// ADMIN USER DASHBOARD
const AdminUserDashboard = Loadable(lazy(() => import('../pages/dashboard/AdminUserDashboard')));

// EMPLOYEE USER DASHBOARD
const EmployeeUserDashboard = Loadable(lazy(() => import('../pages/dashboard/EmployeeUserDashboard')));

// CLIENT FIELDS
const FieldsList = Loadable(lazy(() => import('../pages/dashboard/FieldsList')));
const FieldCreate = Loadable(lazy(() => import('../pages/dashboard/FieldCreate')));

// DEMO USER FIELDS
const DemoUserFieldsList = Loadable(lazy(() => import('../pages/dashboard/DemoUserFieldsList')));
const DemoUserFieldCreate = Loadable(lazy(() => import('../pages/dashboard/DemoUserFieldCreate')));

// CLIENT API Calls
const APICallsList = Loadable(lazy(() => import('../pages/dashboard/APICallsList')));

// DEMO USER API Calls
const DemoUserAPICallsList = Loadable(lazy(() => import('../pages/dashboard/DemoUserAPICallsList')));

// ADMIN API Calls
const AdminAPICallsList = Loadable(lazy(() => import('../pages/dashboard/AdminAPICallsList')));

// EMPLOYEE API Calls
const EmployeeAPICallsList = Loadable(lazy(() => import('../pages/dashboard/EmployeeAPICallsList')));

// CLIENT COLLECTIONS
const CollectionsList = Loadable(lazy(() => import('../pages/dashboard/CollectionsList')));
const CollectionCreate = Loadable(lazy(() => import('../pages/dashboard/CollectionCreate')));
const CollectionTest = Loadable(lazy(() => import('../pages/dashboard/CollectionTest')));
const CollectionStart = Loadable(lazy(() => import('../pages/dashboard/CollectionStart')));

// ADMIN COLLECTIONS
const AdminCollectionsList = Loadable(lazy(() => import('../pages/dashboard/AdminCollectionsList')));
const AdminCollectionStart = Loadable(lazy(() => import('../pages/dashboard/AdminCollectionStart')));
const AdminCollectionManual = Loadable(lazy(() => import('../pages/dashboard/AdminCollectionManual')));
const AdminCollectionMapping = Loadable(lazy(() => import('../pages/dashboard/AdminCollectionMapping')));
const AdminCollectionTableMapping = Loadable(lazy(() => import('../pages/dashboard/AdminCollectionTableMapping')));
const AdminCollectionTableFooterMapping = Loadable(lazy(() => import('../pages/dashboard/AdminCollectionTableFooterMapping')));

// EMPLOYEE COLLECTIONS
const EmployeeCollectionsList = Loadable(lazy(() => import('../pages/dashboard/EmployeeCollectionsList')));
const EmployeeCollectionStart = Loadable(lazy(() => import('../pages/dashboard/EmployeeCollectionStart')));
const EmployeeCollectionManual = Loadable(lazy(() => import('../pages/dashboard/EmployeeCollectionManual')));
const EmployeeCollectionMapping = Loadable(lazy(() => import('../pages/dashboard/EmployeeCollectionMapping')));
const EmployeeCollectionTableMapping = Loadable(lazy(() => import('../pages/dashboard/EmployeeCollectionTableMapping')));
const EmployeeCollectionTableFooterMapping = Loadable(lazy(() => import('../pages/dashboard/EmployeeCollectionTableFooterMapping')));

// DEMO USER COLLECTIONS
const DemoUserCollectionsList = Loadable(lazy(() => import('../pages/dashboard/DemoUserCollectionsList')));
const DemoUserCollectionCreate = Loadable(lazy(() => import('../pages/dashboard/DemoUserCollectionCreate')));
const DemoUserCollectionTest = Loadable(lazy(() => import('../pages/dashboard/DemoUserCollectionTest')));
const DemoUserCollectionStart = Loadable(lazy(() => import('../pages/dashboard/DemoUserCollectionStart')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));

// ADMIN INVOICE
const AdminInvoiceList = Loadable(lazy(() => import('../pages/dashboard/AdminInvoiceList')));
const AdminInvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/AdminInvoiceDetails')));
const AdminInvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/AdminInvoiceCreate')));
const AdminInvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/AdminInvoiceEdit')));

// USER
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const AdminUserList = Loadable(lazy(() => import('../pages/dashboard/AdminUserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserEdit = Loadable(lazy(() => import('../pages/dashboard/UserEdit')));


// ADMIN CRON
const AdminCronList = Loadable(lazy(() => import('../pages/dashboard/AdminCronList')));
const CronCreate = Loadable(lazy(() => import('../pages/dashboard/CronCreate')));
const CronEdit = Loadable(lazy(() => import('../pages/dashboard/CronEdit')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
// const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const Privacy = Loadable(lazy(() => import('../pages/Privacy')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
// const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
